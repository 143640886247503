.routes {
	max-width:240px;
	font-size: 17px;
	top: 100%;
	position: absolute;
	background-color:rgba(255, 255, 255, 0.993);
	color: black;
	transition: all 0.3s ease;
}

.routes__item {
	display:flex;
	flex-wrap:wrap;
	padding: 10px 20px;
	transition: all 0.3s ease;
	cursor: pointer;
	min-width: 240px;
	background:none;
	border:none;
	text-align: start;
	font-size: 18px;
}
.from{
	flex-direction: column;
}
.where{
	flex-direction: column;

}
.routes__item:hover{
	background-color: rgba(156, 156, 156, 0.617);
}
.selected-city{
	font-weight: 700;
}
@media (max-width:670px) {
	.routes {
		max-width:90vw;
	}
	
}
