.support{
	position: absolute;
	top: 110%;
	left: -50%;
	background-color: white;
	color: black;
	padding: 15px 22px;
	border-radius: 10px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
}
.support__body{
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.support__item{
	font-size: 14px;
	width: 190px;
	display: flex;
	align-items: center;
	gap: 5px;
}
.support__item img{
	max-width: 32px;
	max-height: 32px;
}

.telegram{
	text-decoration: underline;
	transition: all 0.3s ease;
	cursor: pointer;
}
.telegram:hover{
	transform: scale(1.05);
}

.entering{
	opacity: 1;
	visibility: visible;
}
.entered{
	opacity: 1;
	visibility: visible;
}