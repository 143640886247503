
.tickets{
	color: black;
	position: relative;
	z-index: 2;
	gap: 15px;
	display:flex;
	justify-content: center;
	align-items: center;
}
.form__items{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px 10px 20px;
	gap: 20px;
	background-color:rgba(255, 255, 255, 0.999);
	min-height: 90px;
	border-radius: 10px;
}
.form__item{
	position: relative;
	display: flex;
	align-items: center;
}
.form__item-input{
	cursor: pointer;
	display: flex;
	flex-direction: column;
}
.form__item-subtitle{
	font-size: 14px;
	opacity: 0.9;
}
.form__input{
	padding: 10px 0px;
	border: none;
	outline: none;
	font-size: 20px;
	background-color: transparent;
	color: black;
	border-bottom: 1px solid black;
}

.form__input::placeholder{
	color: black;
}

.arrows-svg{
	position: absolute;
	top: 40%;
	right: 0px;
	cursor: pointer;
}
.calendar-svg{
	z-index: 0;
	position: absolute;
	top: 45%;
	right: -7px;
}
::-webkit-calendar-picker-indicator {
	color: transparent;
	opacity: 0;
	position: absolute;
	z-index: 12;
	width: 100%;
	background-size: contain;
}
.passengers{
	display: flex;
	padding: 10px 15px;
	cursor: pointer;
	max-width: 155px;
}
.passengers .form__item-input{
	align-items: center;
	gap: 15px;
	margin-right: 20px;
}
.form__item-passengers{
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	position: absolute;
	bottom: -100%;
	background-color: white;
	transition: all 0.3s ease;
	max-width: 157px;
	left: 0px;
}

.form__item-passengers-value-block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: 20px;
}
.form__item.passengers{
	transition: all 0.3s ease;
	border-radius:10px;

}
.form__item.passengers:hover{
	background-color: rgba(0, 0, 0, 0.19);
}

.form__item-passenger-btn{
	padding: 10px 20px;
	font-size: 20px;
	border: none;
	background-color: transparent;
	transition: all 0.3s ease;
}
.form__item-passenger-btn:hover{
	background-color: rgba(186, 186, 186, 0.669);
}

.form__item-passengers-value.input{
	max-width: 60px;
	outline: none;
	border: none;
	text-align: center;
	font-size: 18px;
}

input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; 
}

.form__btn{
	cursor: pointer;
	margin-left: 20px;
	
}
.form__btn .form__btn-sub{
	font-size: 18px;
	font-weight: 300;
	border: none;
	background-color: rgba(241, 50, 50, 0.936);
	color: white;
	padding: 0px 20px;
	height: 90px;
	cursor: pointer;
	transition: all 0.3s ease;
	border-radius: 10px;
}
.form__btn .form__btn-sub:hover{
	background-color: rgba(195, 24, 24, 0.936);
}

#date{
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none; 
	cursor: pointer;
	-webkit-calendar-picker-indicator:none;
	-webkit-inner-spin-button:none

}


/* -------------------------------------------- */

.date-animation{
	animation:dateError forwards 0.3s ;
	color: red;
	border-bottom: 1px solid red;
}

.show-passengers{
	opacity: 1;
	position: absolute;
	bottom: -60%;
	left: 0px;
	z-index: 1;
	visibility:visible;

}

@keyframes dateError {
	0%{
		transform: rotateZ(1deg);	
	}
	25%{
		transform: rotateZ(2deg);	
	}
	50%{
		transform: rotateZ(0deg);	
	}
	75%{
		transform: rotateZ(-1deg);	
	}
	90%{
		transform: rotateZ(-2deg);	
	}
	
	90%{
		transform: rotateZ(0deg);	
	}
}



/* media */


@media (max-width:1010px) {
	.tickets{
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	.form__btn{
		margin-top: 20px;
	}
}

@media (max-width:990px) {
	.form__btn-sub{
		width: 50vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
		
	}
	.form__btn-sub{
		max-height: 80px;
	}
	.form__items{
		min-height: 120px;
	}
}
@media (max-width:862px) {
	.routes{
		z-index: 10;
	}
	.form__btn-sub{
		width: 50vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
	}
}

@media (max-width:860px) {
	.form__items{
		margin: 20px;
		height: fit-content;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
}
@media (max-width:580px) {
	.form__btn{
		margin: 0px;

	}
	.form__btn-sub{
		width: 94vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
	}
	.form__items{
		border-radius: 10px;
	}
}

@media (max-width:556px) {
	.form__btn-sub{
		width: 50vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
	}
}

@media (max-width:426px) {
	.form__items{
		padding: 20px 10px;
		margin: 12px;
		border-radius: 10px;
	}
	.form__item-input{
		max-width: 100%;
		margin-right: 5px !important;
	}
	.form__input{
		width: 80vw;
	}
	#date{
		width: 35vw;
		font-size: 17px;
	}
	.passengers{
		padding: 5px;
		width: 35vw;
	}
	.form__btn{
		margin: 0px;

	}
	.form__btn-sub{
		width: 94vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
	}
	.form__item label{
		color: gray;
		font-size: 13px;
	}
	.form__item-passengers{
		cursor: pointer;
		width: 45vw;
		padding: 10px 0px;
	}
	.show-passengers{
		transform: translateX(-8%);
		bottom: -110%;
	}

}

@media (max-width:426px) {
	.calendar-svg{
		background-color: white;
		right: 4px;
	}
	.routes__item{
		width: 90vw;
	}
	.form__items{
		padding: 20px 10px;
		margin: 12px;
		border-radius: 10px;
	}
	.form__item-input{
		max-width: 100%;
		margin-right: 5px !important;
	}
	.form__input{
		width: 80vw;
	}
	#date{
		width: 35vw;
		font-size: 17px;
	}
	.passengers{
		padding: 5px;
		width: 35vw;
	}
	.form__btn{
		margin: 0px;

	}
	.form__btn-sub{
		width: 94vw;
		height: 10vh !important;
		padding: none;
		border-radius: 10px;
	}
	.form__item label{
		color: gray;
		font-size: 13px;
	}
	.form__item-passengers{
		width: 45vw;
		padding: 10px 0px;
	}
	.show-passengers{
		transform: translateX(-8%);
		bottom: -110%;
	}

}

@media (max-width:391px) {
	.form__btn-sub{
		width: 75vw;
	}
	#date{
		width: 80vw;
	}
	.passengers{
		width: 80vw;
	}
	.form__item-passengers{
		width: 80vw;
		transform: translateX(0px);
		padding-top: 5px;
	}
}



