.info{
	margin-top: 50px;
	padding-bottom:40px;
	font-family: 'Yeseva One', cursive;
}

.info .container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.info__title{
	letter-spacing: 5px;
	font-size: 78px;
}
.info__body{
	margin-top: 60px;
	text-align: center;
	font-family: 'Roboto',sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

@media (max-width:1580px) {
	.info__body{
		padding: 0px 15px;
	}
}

@media (max-width:1015px) {
	.info{
		margin-top: 20px;
	}
}
@media (max-width:830px) {
	.info__title{
		letter-spacing: 3px;
		font-size: 58px;
	}
	.info__body{
		margin-top: 30px;
		text-align: center;
		font-family: 'Roboto',sans-serif;
		font-size: 16px;
		font-weight: 600;
		line-height: 26px;
	}
	
}
@media (max-width:360px) {
	.info__title{
		letter-spacing: 3px;
		font-size: 38px;
	}
	.info__body{
		margin-top: 30px;
		text-align: center;
		font-family: 'Roboto',sans-serif;
		font-size: 14px;
		font-weight: 600;
		line-height: 26px;
	}
	
}