.policy{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.694);
	z-index: 10;
	overflow: auto;
	transition: all .3s ease;
}
.policy__body{
	max-width: 80vw;
	position: relative;
	font-family: 'Roboto',sans-serif;
	background-color: white;
	color: black;
	padding:40px 60px 30px 60px;
	border-radius: 20px;
	display: flex;
	gap:20px;
	background-color: rgb(250, 250, 250);
	max-height: 95vh;

	display: flex;
	flex-direction: column;
	gap: 15px;
}
.police__title{
	margin-bottom:10px;
	font-size:22px;
}
.policy__close{
	position: absolute;
	font-size: 36px;
	cursor: pointer;
	right: 15px;
	top:10px;
	transition: all 0.3s ease;
	background-color: transparent;
	border: none;
}

@media (max-width:620px) {
	.policy__body{
		max-width: 90vw;
		padding:20px;
		padding-top: 40px;
		overflow-y: scroll;

	}
	
}