.footer{
	margin-top: 80px;
	border-top: 1px solid rgba(227, 120, 120, 0.226);
	padding-bottom: 60px;
}
.footer__contacts{
	margin-top: 5px;
	font-family: 'Roboto',sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
}
.footer__email{
	font-weight: 800;
}

.footer__rights{
	max-width: fit-content;
	margin: 0 auto;
	text-transform: uppercase;
	color: gray;
	align-self:flex-start;
}
.footer__rights-btn{
	font-family: 'Roboto',sans-serif;
	font-weight: 600;

	background:transparent;
	color:black;
	text-decoration:underline;
	font-size:18px;
	padding:0px;
	border:none;
	cursor:pointer

}