.about{
	margin-top: 145px;
	padding: 30px 10px 0px 10px;
	background:linear-gradient(rgb(255, 255, 255),rgb(242, 253, 200));
	color: #1d1d1de4;
}
.about .container{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
}
.about__title{
	text-align: center;
	font-size: 42px;
}
.about__value{
	position: relative;
	font-weight: 800;
	font-size: 94px;
	color: #1d1d1d7f;
	text-align: center;
}
.about__value::after{
	content: 'clients monthly';
	position: absolute;
	font-size: 22px;
	color: #1d1d1de4;
	top: 50%;
	left:50%;
	transform: translate(-50%,-50%);
}
@media (max-width:360px) {
	.about__title{
		text-align: center;
		font-size: 38px;
	}
	.about__value{
		position: relative;
		font-weight: 800;
		font-size: 82px;
		color: #1d1d1d7f;
		text-align: center;
	}
	.about__value::after{
		content: 'clients monthly';
		position: absolute;
		font-size: 22px;
		color: #1d1d1de4;
		top: 50%;
		left:50%;
		transform: translate(-50%,-50%);
	}
}

.about__item{
	font-family: 'Roboto',sans-serif;
	display: flex;
	flex-direction: column;

}
.about__item-title{
	text-align: center;
	font-size: 24px;
	font-weight: 600;
}
.about__item-text{
	margin-top: 20px;
	font-size: 15px;
	font-weight: 600;
	line-height: 25px;
}
.about__gallery{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
}
.about__gallery-item .first{
	max-width: 920px;
	max-height: fit-content;
	border-radius: 50px;
}
.about__gallery-item img{
	max-width: 460px;
	max-height: 300px;
	border-radius: 50px;
}

@media (max-width:960px) {
	.about__gallery-item .first{
		max-width: 460px;
		max-height: 300px;
		border-radius: 50px;
	}
}
@media (max-width:470px) {
	.about{
		margin-top: 20px;
	}
	.about__gallery-item .first{
		max-width: 360px;
		max-height: 300px;
		border-radius: 50px;
	}
	.about__gallery-item img{
		max-width: 360px;
		max-height: 300px;
		border-radius: 50px;
	}
}
@media (max-width:370px) {
	.about__gallery-item .first{
		max-width: 260px;
		max-height: 300px;
		border-radius: 50px;
	}
	.about__gallery-item img{
		max-width: 260px;
		max-height: 300px;
		border-radius: 50px;
	}
}